import React from "react"
import { css } from "@emotion/core"
import { useStaticQuery, Link, graphql } from "gatsby"

import { rhythm } from "../utils/typography"

export const HeaderLink = ({to, children, invert}) => {
  return (
    <Link to={to} css={css`padding-right: 5px; ${invert ? 'color: white;' : ''}`}>
      {children}
    </Link>  
  )
}

export default ({ children, invert }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <div
      css={css`
        margin: 0 auto;
        z-index: 5;
        max-width: 700px;
        padding: ${rhythm(2)};
        padding-top: ${rhythm(1.5)};
      `}
    >
      <Link to={`/`}>
        <h3
          css={css`
          ${invert ? 'color: white;' : ''}
            margin-bottom: ${rhythm(2)};
            display: inline-block;
            font-style: normal;
            z-index: 50;
          `}
        >
          {data.site.siteMetadata.title}
        </h3>
      </Link>
      <div css={css`float: right;`}>
        <HeaderLink invert={invert} to={`/contact/`}>Contact Me</HeaderLink>
        <HeaderLink invert={invert} to={`/workspace/`}>My Work</HeaderLink>        
        <HeaderLink invert={invert} to={`/about/`}>About</HeaderLink>
      </div>
      {children}
    </div>
  )
}